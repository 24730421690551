/**
 * We need to increase the z-index of the base material library CSS files so that
 * mat-select dropdowns don't appear in the background when used within a modal.
 *
 *  https://github.com/creativetimofficial/ct-material-dashboard-pro-angular/issues/351
 */
.cdk-overlay-container {
  z-index: 10000 !important;

  mat-option {
    &.chilled-coin-option {
      height: 40px;
      .coin-name-short {
        line-height: 1em;
      }
    }
  }
}
