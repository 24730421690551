$coin-zoom-green: #60bb46;
$coin-zoom-green-hover: #7df15b;
$coin-zoom-darkgreen: #015d01;
$coin-zoom-warning: #d39a33;

$coin-zoom-blue: #4a61b5;
$coin-zoom-lightblue: #95a2d2;
$coin-zoom-lightgrey: #dcdcdc;

$coin-zoom-tab-border: #dedede;

$coin-zoom-night-theme-background: black;
$coin-zoom-night-pullout-background: #454545;
$coin-zoom-night-theme-background-highlight: #999;
$coin-zoom-night-table-hdr-background: #212529;
$coin-zoom-day-table-hdr-background: #343a40;
$coin-zoom-day-table-hdr-border: #484d57;

$coin-zoom-white: #fff;
$coin-zoom-pullout-background: #fafafa;

$coin-zoom-error: red;
$coin-zoom-negative-qty: #e24646;

$coin-zoom-scrollbar-track-colour: #555;
$coin-zoom-scrollbar-thumb-colour: #afafaf;
$coin-zoom-scrollbar-thumb-hover-colour: #cfcfcf;
$coin-zoom-trade-entry: $coin-zoom-lightgrey;

$coin-zoom-card-payment-minor-info-colour: gray;
$coin-zoom-card-payment-minor-info-colour-night: #bebebe;

$coin-zoom-chart-tooltip-background: rgba(151, 206, 228, 0.5);

$coin-zoom-date-quick-filter-colour: #5cb85c;

$coin-zoom-select-card-colour: rgb(104, 169, 126);
$coin-zoom-preferred-card-colour: #d8d8d8;
$coin-zoom-gold-card-colour: #f1ce73;
$coin-zoom-platinum-card-colour: #8c8c8c;
$coin-zoom-black-card-colour: #676565;
