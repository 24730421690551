/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'sass:map';

@import 'responsive';
@import 'common';
@import 'custom';
@import 'spinner';
@import 'layout';
@import 'common-utils';
@import 'night-theme';
@import 'variable';
@import 'print';
@import 'coins';
@import 'mat-table';
@import 'mat-select';

@import 'chartiq/plugins/activetrader/cryptoiq.css';
@import 'chartiq/plugins/tfc/tfc.css';

@import 'roboto-fontface/css/roboto/roboto-fontface.css';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import 'bootstrap-scss/bootstrap';
@import 'font-awesome/css/font-awesome.min.css';
@import 'ngx-toastr/toastr';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$cz-green: (
  50: #eaf7e8,
  100: #ceeac8,
  200: #afdda5,
  300: #8fd07f,
  400: #76c663,
  500: $coin-zoom-green,
  600: #55ac3d,
  700: #489932,
  800: #3d8828,
  900: #266914,
  A100: #d8ffd1,
  A200: #acff9e,
  A400: #80ff6b,
  A700: #6bff52,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

$cz-app-primary: mat.define-palette($cz-green);
$cz-app-warn: mat.define-palette(mat.$red-palette);

$light-dividers: rgba($coin-zoom-green, 0.87);
$dark-dividers: rgba($coin-zoom-green, 1);

$cz-app-light-theme-base: mat.define-light-theme(
  (
    color: (
      primary: $cz-app-primary,
      accent: $cz-app-primary,
      warn: $cz-app-warn,
    ),
  )
);
$cz-app-dark-theme-base: mat.define-dark-theme(
  (
    color: (
      primary: $cz-app-primary,
      accent: $cz-app-primary,
      warn: $cz-app-warn,
    ),
  )
);

$cz-app-light-theme: map.deep-merge(
  $cz-app-light-theme-base,
  (
    color: (
      foreground: (
        divider: $light-dividers,
        dividers: $light-dividers,
      ),
      background: (
        selected-button: rgba($coin-zoom-green, 1),
      ),
    ),
  )
);

$cz-app-dark-theme: map.deep-merge(
  $cz-app-dark-theme-base,
  (
    color: (
      foreground: (
        divider: $dark-dividers,
        dividers: $dark-dividers,
      ),
    ),
  )
);

.themeNight {
  @include mat.all-legacy-component-colors($cz-app-dark-theme);
}
body,
.themeDay {
  @include mat.all-legacy-component-themes($cz-app-light-theme);
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;
  font-weight: 400;
  font-size: 9pt;
  text-align: left;
  line-height: 1.6em;
}

body {
  stx-hu-tooltip {
    position: absolute;
    left: -50000px;
    z-index: 30;
    white-space: nowrap;
    padding: 6px;
    border: 1px solid gray;
    background-color: $coin-zoom-chart-tooltip-background;
    color: black;
  }

  .depth-chart-tooltip {
    background-color: $coin-zoom-chart-tooltip-background;
    color: black;
    min-width: 160px;
    overflow: visible;

    .depth-tooltip-label {
      display: inline-block;
      width: 60px;
      font-family: 'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;
      font-size: 14px;
      line-height: 1.6em;
      font-weight: 700;
    }

    .depth-tooltip-value {
      display: inline-block;
      width: 100px;
      font-family: 'Helvetica Neue', Helvetica, 'Roboto', Arial, sans-serif;
      font-size: 14px;
      line-height: 1.6em;
      font-weight: 700;
      text-align: right;
    }
  }
}

.threeDS-verified-card {
  color: $coin-zoom-green;
}

.deposit-icon {
  svg {
    width: 30px;
    fill: #000;
  }
}

.activeButton {
  .deposit-icon {
    svg {
      fill: #fff;
    }
  }
}

.activeSettings {
  background-color: black !important;
  color: #fff !important;
}

.system-notification {
  padding: 10px;
  background: #dddddd;
  border-radius: 6px;
  width: 600px;
  position: absolute;
  z-index: 200;
  top: 15px;
  left: 280px;
  font-size: 10pt;

  .ctrls {
    margin-top: 5px;
    button {
      float: right;
    }
  }
}

.gift-reward-val {
  amount {
    span.trailing-zeros {
      color: $coin-zoom-green;
    }
  }
}
.card-payments-section {
  .payment-scroll-viewport {
    .scrollable-payment-row {
      .status-icon {
        color: #d3d2d2;
      }

      amount {
        span.trailing-zeros {
          color: black;
        }
      }

      .linked-reward-amounts {
        span.trailing-zeros {
          color: $coin-zoom-green;
        }
      }

      .linked-currency-amount {
        color: $coin-zoom-card-payment-minor-info-colour-night;
        amount {
          color: $coin-zoom-card-payment-minor-info-colour-night;
          span.trailing-zeros {
            color: $coin-zoom-card-payment-minor-info-colour-night;
          }
        }
      }

      .payment-info {
        .payment-date {
          color: $coin-zoom-card-payment-minor-info-colour-night;
        }
      }
    }
  }
}

.tooltip-help {
  padding: 5px;
  &.what-is-new {
    font-size: 1.2em;
  }
}

.qrc-img {
  height: 150px;
  width: 150px;
  margin: auto;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $coin-zoom-night-theme-background;
  display: inline-block;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 8px solid $coin-zoom-night-theme-background;
  display: inline-block;
}

.arrow-up:hover {
  border-bottom-color: $coin-zoom-night-theme-background-highlight;
}

.arrow-down:hover {
  border-top-color: $coin-zoom-night-theme-background-highlight;
}

.stx_yaxis {
  color: #333;
}

.stx_xaxis {
  color: #333;
}

.chart-footer {
  border-top: 1px solid $coin-zoom-night-theme-background !important;
  color: $coin-zoom-night-theme-background;
}

.scrollable-order-row {
  padding: 4px 0;

  div {
    display: inline-block;
    vertical-align: middle;

    &.side-indicator {
      display: flex;
    }
  }
}

.side-indicator {
  width: 37px;
  height: 16px;
  line-height: 19px;
  display: flex;
  float: right;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 2px;
  font-size: 10px;
  padding: 2px 5px;

  &.typeSell {
    background: $coin-zoom-negative-qty;
    color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  &.typeBuy {
    background: $coin-zoom-green;
    color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  span {
    margin: auto;
  }
}
.popout-toolbar-btn {
  opacity: 0.4;
}

.symbol-frame {
  position: relative;
  width: 130px;
}

menu-select {
  font-size: 9pt;
  vertical-align: middle;
  display: inline-block;
  padding: 5px 5px;
  background: transparent;
  color: #000;
  border: 0px transparent;
  list-style: none;
  cursor: pointer;
}

menu-select span.title {
  color: #000;
  transition: color 0.2s 0s;
  font-size: 11px;
}

menu-select span.title:hover {
  color: #000;
}

menu-select:after {
  content: '';
  position: relative;
  top: -2px;
  right: 0px;
  margin-left: 5px;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-right: solid thin #949494;
  border-bottom: solid thin #949494;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  zoom: 1;
  color: #c5c7c9;
}

menu-select-options menu-option {
  padding: 0px 20px;
  position: relative;
  text-align: left;
  display: block;
  line-height: 27px;
  height: 27px;
  color: #666;
}

menu-select-options menu-option span {
  font-weight: 300;
}

menu-select-options menu-option:hover {
  color: #000;
  background: #f3f3f3;
}

menu-select-options {
  margin: 5px 0px 0px -5px;
  padding-top: 0px;
  position: absolute;
  min-width: 200px;
  max-height: 90%;
  overflow: auto;
  background: #fff;
  display: none;
  z-index: 999;
  left: inherit;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
}

menu-select:hover menu-select-options {
  display: block;
  padding: 10px 0 10px 0;
}

menu-select-options {
  .active-instrument {
    background-color: #4a61b5;
    color: white;
  }

  .active-instrument:hover {
    background-color: #8f9ccc;
    color: white;
  }
}

textarea {
  &.reference-text {
    resize: none;
    height: 5rem !important;
  }
}

.min-height200 {
  min-height: 200px !important;
}

ng-multiselect-dropdown {
  .multiselect-dropdown {
    border-color: $coin-zoom-green;
    span {
      &.dropdown-btn {
        border-color: $coin-zoom-green !important;
      }
    }
  }
}

.help-box {
  border: 1px solid green;
  padding: 10px;
  border-radius: 7px;
  background: $coin-zoom-lightgrey;
}

.referral-tooltip.tooltip.show {
  opacity: 1;
}

.referral-token-section {
  .form-group {
    background-color: $coin-zoom-lightgrey;
    color: black;
  }
}

.margin-direction {
  border-radius: 2px;
  padding: 2px 5px;
  color: white;
  font-size: 10px;
  &.long {
    background: $coin-zoom-green;
  }
  &.short {
    background: red;
  }
}

.table_tab {
  float: left;
  font-size: 12px;
  border: 1px solid $coin-zoom-tab-border;
  text-align: center;
  padding: 4px 20px 4px 20px;
  margin: 5px 0 0 0;
  cursor: pointer;

  &.activeTab {
    color: #fff;
    background: $coin-zoom-green;
    border: 1px solid $coin-zoom-green;
  }

  &.table_tab_first {
    border-radius: 0;
  }

  &.table_tab_last {
    border-radius: 0;
  }
}

.landingPage_main {
  .sell_buy,
  .market,
  .openOrder,
  .chartsection,
  .orderEx,
  .trade_history {
    border: 1px solid #f2f2f2;
  }
}

.tab-container {
  border: 1px solid $coin-zoom-tab-border;
  clear: both;
}

.leverage-indicator {
  margin-left: 1px;
  border: 1px solid $coin-zoom-green;
  border-radius: 5px;
  color: $coin-zoom-green;
  padding: 2px;
}

html .themeNight {
  .form-group {
    color-picker {
      input {
        color: black !important;
      }
    }
  }

  .referral-token-section {
    .form-group {
      background-color: grey;
      color: $coin-zoom-white;
    }
  }

  .referral-tooltip,
  .destination-tooltip {
    color: $coin-zoom-white;
    .arrow::before {
      border-top-color: grey;
    }
    .tooltip-inner {
      background: grey;
      color: $coin-zoom-white;
    }
  }

  .system-notification {
    background: #868686;
  }

  .spend-currency-container {
    .currency-popup {
      input {
        color: black !important;
        cursor: pointer;
      }
      input::placeholder {
        color: black !important;
      }
    }
  }

  .card-payments-section {
    .date-filter {
      input {
        color: black !important;
      }
      input::placeholder {
        color: black !important;
      }
    }
    .payment-scroll-viewport {
      amount {
        span.trailing-zeros {
          color: black;
        }
      }

      .linked-currency-amount {
        color: $coin-zoom-card-payment-minor-info-colour-night;
        amount {
          color: $coin-zoom-card-payment-minor-info-colour-night;
          span.trailing-zeros {
            color: $coin-zoom-card-payment-minor-info-colour-night;
          }
        }
      }

      .payment-info {
        .payment-date {
          color: $coin-zoom-card-payment-minor-info-colour-night;
        }
      }
    }
  }

  .payment-controls-section {
    .date-filter {
      input {
        color: black !important;
      }
      input::placeholder {
        color: black !important;
      }
    }
  }

  .embedded-web-link:hover {
    color: #cccccc;
  }

  .help-box {
    border: 1px solid green;
    background: #8a8a86;
  }

  .tooltip-help {
    .tooltip-inner {
      color: black;
      background-color: white;
    }

    .arrow::before {
      border-top-color: white;
    }
  }

  ng-multiselect-dropdown {
    .multiselect-dropdown {
      .dropdown-list {
        background: $coin-zoom-night-theme-background;
        color: $coin-zoom-white;

        .multiselect-item-checkbox {
          input[type='checkbox'] + div {
            color: $coin-zoom-white;
          }
        }
      }
    }
  }

  .card-depositwithdraw-container {
    .form-control:focus {
      background-color: transparent;
    }
  }

  .profile-container {
    &.add-ip-section {
      background: rgb(50, 50, 50) !important;
    }
  }

  .message-toggle {
    .message-icon {
      color: $coin-zoom-white;
    }
  }

  .messages {
    background: $coin-zoom-night-pullout-background;

    .message {
      border-color: $coin-zoom-white;

      &.success {
        border-color: $coin-zoom-green;
      }
    }
  }

  .message-controls {
    background: $coin-zoom-night-pullout-background;
  }

  .arrow-up {
    border-bottom-color: $coin-zoom-white;
  }

  .arrow-down {
    border-top-color: $coin-zoom-white;
  }

  .arrow-up:hover {
    border-bottom-color: $coin-zoom-scrollbar-thumb-hover-colour;
  }

  .arrow-down:hover {
    border-top-color: $coin-zoom-scrollbar-thumb-hover-colour;
  }

  .chart-footer {
    color: $coin-zoom-white !important;
    border-top: 1px solid $coin-zoom-white !important;
  }

  .chartSeries {
    color: white;
  }

  .comparisonWrapper {
    background: #848088;
    border: 1px solid white;
  }

  .comparisonWrapper:hover {
    background-color: #21323f;
  }

  .mdb-select {
    border: 1px solid $coin-zoom-green !important;
  }

  /* The container */
  .container {
    a {
      color: $coin-zoom-white !important;
    }
  }

  .table_sec {
    td,
    th {
      padding: 10px;
      border: 1px solid $coin-zoom-white !important;
    }
  }

  .profile-body-div {
    background: #2f3332 !important;
  }

  .profile-heading-div {
    padding: 15px !important;
  }

  .terms .cnten h1 {
    font-size: 25px;
    color: $coin-zoom-white !important;
  }

  .left_side .bnt button {
    border: 1px solid $coin-zoom-white !important;
  }

  .chart-order-book .table-content {
    background: transparent;
    text-align: right;
    color: $coin-zoom-white;
  }

  .chartContainer .stx_grid {
    color: #000 !important;
  }

  .chartContainer .stx_grid_dark {
    color: #000;
  }

  .chartContainer .stx_grid_border {
    color: #000;
  }

  .stx_yaxis {
    color: $coin-zoom-white !important;
  }

  .stx_xaxis {
    color: $coin-zoom-white !important;
  }

  .stx_xaxis_dark {
    color: $coin-zoom-white !important;
  }

  .social {
    .text {
      p {
        color: $coin-zoom-white;
      }
    }
  }

  .table-section {
    background: transparent !important;
  }

  .text-center.btn-div {
    border: 0px solid;
    border-radius: 5px;
    outline: none;
  }

  .activeTab {
    background-image: linear-gradient(90deg, $coin-zoom-green, $coin-zoom-green);
  }

  .profile-heading {
    height: 35px;
    font-size: 18px;
    background: #232323 !important;
  }

  // referral -----------
  .reff {
    .profile-heading {
      background: #000 !important;
    }

    .sharsection {
      background: #303332 !important;

      .refe_link {
        color: $coin-zoom-white;

        span {
          background: $coin-zoom-white;
          color: #000;
        }
      }

      .refe {
        background: #000;
      }
    }

    .zoomtokensection {
      background: $coin-zoom-green !important;
      color: $coin-zoom-white;
      border-radius: 10px;
      border: 0px solid #000 !important;

      .text {
        color: $coin-zoom-white;
        border-radius: 10px;
        padding: 25px;
        height: 100%;

        h1 {
          color: $coin-zoom-white !important;
        }
      }
    }
  }

  .profile-bg {
    background: #1b1b1b;
  }

  .market-name-header {
    color: $coin-zoom-white !important;
  }

  .chartContainer1 .stx_grid {
    /* Default Grid */
    color: $coin-zoom-white;
    opacity: 0.03 !important;
    border-style: solid;
  }

  .chartContainer1 .stx_grid_dark {
    /* Default Grid dividers */
    color: $coin-zoom-white;
    opacity: 0.03 !important;
    border-style: solid;
  }

  .chartContainer1 .stx_grid_border {
    /* Default Grid borders */
    color: $coin-zoom-white;
    border-style: solid;
  }

  .chartContainer .stx_grid {
    /* Default Grid */
    color: $coin-zoom-white !important;
    opacity: 0.1 !important;
    border-style: solid;
  }

  .chartContainer .stx_grid_dark {
    /* Default Grid dividers */
    color: $coin-zoom-white;
    opacity: 0.4 !important;
    border-style: solid;
  }

  .chartContainer .stx_grid_border {
    /* Default Grid borders */
    color: $coin-zoom-white;
    border-style: solid;
  }

  .notAvailable {
    color: $coin-zoom-white;
  }

  .limitActive {
    margin-top: 0px;
    color: white !important;
    border-bottom: 2.5px solid #4a61b5 !important;
  }

  .settings-container {
    background: #000000;
  }

  .text-center.blue-clr,
  .text-right.blue-clr,
  .text-center,
  select option {
    //color: $coin-zoom-white;
    background: transparent;
  }

  option {
    color: #000000;
  }

  .modal-content,
  .otpmain .otpsub {
    background: #323232 !important;
  }

  .modal-xxlg .head,
  .modal-xlg .head,
  .modal-lg .head,
  .modal-sm .head,
  .modal-xsm .head,
  .otpmain .otpsub .head {
    background: #000000 !important;
  }

  .otpmain .otpsub .by button {
    border-color: #000 !important;
    font-size: 15px;
  }

  .form-group label,
  .form-group input,
  .form-group textarea,
  select,
  h4 {
    color: $coin-zoom-white !important;
    background: transparent;
  }

  .profile-container {
    background: #000000 !important;

    input,
    select,
    textarea,
    .input-label {
      background: transparent !important;
      border: 1px solid $coin-zoom-green !important;
      color: $coin-zoom-white;
    }
  }

  .activate-card-container {
    input,
    select,
    .input-label {
      background: transparent !important;
      border: 1px solid $coin-zoom-green !important;
      color: $coin-zoom-white;
    }
  }

  .head {
    color: $coin-zoom-white !important;
  }

  color: $coin-zoom-white !important;

  /* -------- Axis Styles -------- */
  .crossY,
  .stx_crosshair_y {
    /* Vertical Crosshair line size */
    height: 0px;
    border-bottom: dashed rgba(255, 255, 255, 0.8) 1px;
    background-color: transparent;
    margin-top: -1px !important;
    /* since we're using border-top to draw the actual line */
  }

  .crossX,
  .stx_crosshair_x {
    /* Horizontal Crosshair line size */
    width: 0px;
    border-left: dashed rgba(255, 255, 255, 0.8) 1px;
    background-color: transparent;
  }

  .stx-panel-title {
    color: $coin-zoom-white;
  }

  .chartContainer {
    background-color: transparent !important;
  }

  color: $coin-zoom-white;

  color: $coin-zoom-white;
  background-color: #000000;

  .landingPage_main {
    .sell_buy,
    .market,
    .openOrder,
    .chartsection,
    .orderEx,
    .trade_history {
      background: #000000;
      border: 1px solid #323232;
    }

    .togla {
      div {
        color: $coin-zoom-white !important;
        line-height: 1.1em !important;
      }
    }

    .card-title3 {
      width: 75%;

      ul {
        li {
          background: #1b1b1b !important;

          .sm_tabs {
            color: $coin-zoom-white !important;
            background: #1b1b1b;
          }
        }
      }
    }

    .stylish-input-group {
      input {
        color: $coin-zoom-white;
      }

      button {
        border: 0;
        background: #000 !important;
      }
    }

    .error {
      background-color: black !important;
    }
  }

  .result {
    th {
      border-left: 1px solid #000 !important;
    }
  }

  .sell_buy {
    input,
    select {
      background: transparent !important;
      border: 1px solid $coin-zoom-green !important;
      color: #000;
    }

    .cry {
      background: transparent !important;
    }
  }

  .tab {
    span {
      background: transparent !important;
      color: $coin-zoom-white !important;
      cursor: pointer;
    }
  }

  ::placeholder {
    color: white;
  }

  .trailing-zeros {
    color: grey;
  }

  .active-market {
    .trailing-zeros {
      color: #b5acac;
    }
  }

  .red-clr {
    .trailing-zeros {
      color: #b36f6f;
    }
  }

  .green-clr {
    .trailing-zeros {
      color: #6b8479;
    }
  }

  .chartiq-toolbar-opt {
    filter: brightness(1);
  }

  .sell_buy {
    input,
    select {
      background: transparent !important;
      border: 1px solid $coin-zoom-green !important;
      color: $coin-zoom-white !important;
    }

    input:focus {
      box-shadow: none;
    }
  }

  stx-hu-tooltip {
    color: white;
  }

  .depth-chart-tooltip {
    color: white;
  }

  .zoomcoin {
    .head {
      background: $coin-zoom-green !important;

      .left_H .text {
        color: $coin-zoom-white !important;

        h1 {
          color: $coin-zoom-white !important;
        }
      }

      .right_H {
        background: #000 !important;

        h1 {
          color: #000 !important;
        }

        .headtag {
          h1 {
            color: $coin-zoom-white !important;
          }
        }
      }

      .text_L {
        color: $coin-zoom-white !important;
      }
    }
  }

  .chart-order-book {
    background: transparent;
  }

  .chart-order-book .table-content {
    li {
      color: $coin-zoom-white !important;
    }
  }

  menu-select {
    span.title,
    span.title:hover {
      color: $coin-zoom-white !important;
    }
  }
}

body {
  color: #000000;
  background-color: $coin-zoom-white;
}

.alert-danger {
  top: 76px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  position: absolute !important;
  font-size: 13px !important;
  color: #ff3d00 !important;
}

.modal-lg {
  max-width: 600px;
}

.modal-xlg {
  max-width: 800px;
}

.modal-xxlg {
  max-width: calc(100vw - 200px);
}

.trailing-zeros {
  color: #cac7cb;
}

samp {
  .trailing-zeros {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  .usd-equivalent {
    font-family: monospace, monospace;
    font-size: 1em;
  }
}

.section-chooser {
  display: inline-block;
  margin-left: 200px;
  margin-top: 15px;

  .button-div {
    display: inline-block;
    margin-right: 20px;

    .setting-option-btn {
      width: 160px;
    }
  }
}

.current-market-mid,
.fontSize14 {
  font-size: 14px;

  span.trailing-zeros {
    font-size: 14px;
  }
}

.bid-table {
  padding: 0;

  span.trailing-zeros {
    font-size: 10px;
  }

  .table-heading,
  .table-content ul {
    padding-right: 10px;
  }
}

.estimated-balance {
  span.trailing-zeros {
    font-size: 16px;
  }
}

.red-clr {
  color: $coin-zoom-negative-qty !important;
  .trailing-zeros {
    color: #b36f6f;
  }
}

.green-clr {
  color: $coin-zoom-green;
  .trailing-zeros {
    color: #6b8479;
  }
}

.transaction-scrollable-row {
  text-align: center;
  padding: 5px 0 5px 0;

  div {
    display: inline-block;
    padding-right: 10px;
  }
}

.dropdown-menu-item {
  cursor: pointer;
  padding: 5px;
}
.dropdown-menu-item:hover {
  background-color: #b0c4de;
}
.export-menu {
  .dropdown-menu-item {
    font-size: 12px;
    font-family: sans-serif;
  }
}

.modal-xxlg,
.modal-xlg,
.modal-lg,
.modal-sm,
.modal-xsm {
  .modal-body {
    padding: 35px 30px 15px 30px;

    .head {
      margin: 0;
    }
  }

  .head {
    padding: 10px !important;
    background: #1d2435 !important;
    border: none !important;
    color: $coin-zoom-white !important;
    border-radius: 10px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    text-align: center;
    margin: 10px 10px 0 10px;

    span {
      font-size: 20px;
      color: $coin-zoom-white;
      font-weight: 900;

      button {
        color: $coin-zoom-white;
        margin-top: 5px;
        opacity: 1;
      }

      button:hover {
        color: $coin-zoom-white !important;
      }
    }
  }

  .modal-content {
    margin-top: 50px;
  }

  .signtogl {
    color: $coin-zoom-green;
    text-decoration: none !important;
    font-size: 9px;
  }

  .signtogl:hover {
    color: #000;
  }
}

.modal-sm {
  max-width: 400px !important;
}

.modal-xsm {
  max-width: 200px !important;
}

//This below css is for the outer container of every screen
.pos-rel {
  position: relative;
}

.main-container {
  padding-top: 20px;
  padding-bottom: 30px;
  height: 570px;
  overflow-y: auto;
}

.m0 {
  margin: 0 !important;
}

.main-card {
  min-height: 485px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.second-card {
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0px 0px 0px 10px !important;
}

.balance-header {
  padding: 15px;
  border-bottom: 1px solid #f2f2f2;
  background-color: white;
}

.header-title {
  font-size: 21px;
  color: #404040;
  text-transform: uppercase;
  margin: 0;
  padding-right: 20px;
  display: inline-block;
}

.body-container {
  position: relative;
  padding: 15px;
}

.table-section {
  // background: #1d2435;
  margin-right: 0;
  margin-bottom: 5px;
}

.table-css {
  width: 100%;
}

.table-css th {
  font-size: 12px !important;
  height: 27px !important;
  padding: 5px 10px !important;
  border-right: 2px solid white;
}

.table-css th:last-child {
  border-right: none;
}

.w100 {
  width: 100%;
}

.balance-fields {
  padding: 10px;
}

.disp-inline-blck {
  display: inline-block;
}

.balances-css {
  color: #6dbd70;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
}

.form-div {
  padding: 20px;
}

.input-border-none-css {
  border: 0 !important;
  border-bottom: 1px solid #404040 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

//search
.search-input {
  padding: 9px;
}

.search-icon {
  margin-top: 10px;
}

.search-ul {
  list-style: none;
  float: right;
  margin: 0;
  padding: 0;
}

.mb0 {
  margin-bottom: 0;
}

.tabtogal {
  .nav-link {
    cursor: not-allowed !important;
  }
}

.btn-success {
  background-image: linear-gradient(90deg, #21610f, $coin-zoom-green);
}

.btn-danger {
  background-image: linear-gradient(to right, #a10303, red);
}

.btn-copy {
  background-image: linear-gradient(to right, #c2c662, yellow);
}

.btn-cancel {
  background-image: linear-gradient(90deg, #aaaaaa, $coin-zoom-lightgrey);
}

.cc-btn.cc-deny {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(227, 81, 70) !important;
  border-color: transparent !important;
  width: 140px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.send .footer {
  bottom: 0 !important;
}

.setting .footer {
  bottom: 10px !important;
}

h1 {
  span.trailing-zeros {
    font-size: 24px;
  }
}

.toolbar menu-select span.title {
  color: $coin-zoom-white !important;
}

.chart-order-book .table-content {
  background: transparent;
  text-align: right;
}

.zoomset {
  background: $coin-zoom-green !important;
}

.zoomcoin .head .right_H .text_L .zInput button {
  color: $coin-zoom-white !important;
}

.forget .footer {
  position: absolute !important;
}

.toast-container {
  z-index: 9999999999 !important;
}

.ui-inputswitch.ui-inputswitch-checked {
  .ui-inputswitch-slider {
    &:before {
      background-color: $coin-zoom-green;
    }
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.embedded-web-link {
  color: inherit;
  text-decoration: underline;
}

.checkbox-div {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;

  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-div input {
  position: absolute;

  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-div {
  .checkmark {
    position: absolute;
    top: 0;
    border: 1px solid $coin-zoom-green;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transaparent;
  }
}

.disabled-checkbox-div {
  .checkmark {
    border: 1px solid #4d4d4d !important;
  }

  .checkmark:hover {
    cursor: not-allowed;
  }
}

.disabled-checkbox-div:hover {
  cursor: not-allowed;
}

/* When the checkbox is checked, add a blue background */
.checkbox-div input:checked ~ .checkmark {
  background-color: $coin-zoom-green;
}

.disabled-checkbox:checked ~ .checkmark {
  background-color: #4d4d4d !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-div {
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
}

/* Show the checkmark when checked */
.checkbox-div input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-div .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

select {
  height: 40px !important;
  font-size: 14px !important;
  border: 1px solid $coin-zoom-green !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

select:disabled {
  cursor: not-allowed;
}

.two-factor {
  overflow-y: auto;
  max-height: calc(100vh - 235px);
}

.card-depositwithdraw-container {
  clear: both;
  width: 50%;
  min-width: 800px;
  position: relative;

  .inflight-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .in-flight {
    opacity: 0.5;
  }
}

.card-amount {
  padding: 10px 25px 0 25px;
  margin-bottom: -10px;
}

@media (max-width: 1500px) {
  .landingPage_main {
    .sell_buy {
      .togla {
        div.my-balance-summary {
          span {
            amount {
              .trailing-zeros {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.clipboard-icon,
.action-icon {
  opacity: 0.8;
  cursor: pointer;
}

.clipboard-icon:hover,
.action-icon:hover {
  opacity: 1;
}

.transactions-container {
  .filters {
    ng-multiselect-dropdown {
      .selected-item {
        max-width: 120px !important;
      }
    }
  }
}

.toast-right-here {
  right: 12px;
}

.closing-orders-row {
  width: 100%;
  padding: 10px 20px 10px 20px;
  background: grey;
  .inner-table {
    width: 70%;

    .thead-dark {
      display: flex;
      div {
        display: inline-block;
        color: white;
        background-color: $coin-zoom-night-table-hdr-background;
        border-color: $coin-zoom-night-theme-background;
        font-size: 12px !important;
        padding: 5px 10px !important;
        font-weight: 700;
        border-right: 2px solid $coin-zoom-night-theme-background;
      }

      &.themeNight {
        div {
          color: $coin-zoom-green;
        }
      }
    }

    div.table {
      width: 100%;
    }
    div.scrollBody {
      width: 100%;
    }
    .order-row {
      background: white;
      &.themeNight {
        background: black;
      }
      .order-cell {
        padding: 5px;
        display: inline-block;

        .price {
          .price-label {
            text-align: left;
            width: 70px;
            display: inline-block;
          }
        }

        &.order-action {
          .btn {
            padding: 0;
            margin-top: -4px;
            margin-right: 10px;

            .position-ctrl-icon {
              font-size: 20px;
              opacity: 0.9;
              &.cancel-icon {
                color: $coin-zoom-error;
              }
              &.edit-icon {
                color: $coin-zoom-green;
              }
            }
            .position-ctrl-icon:hover {
              opacity: 1;
            }

            &.btn-cancel-position {
              margin-right: 5px;
            }
          }
        }
      }
      &.no-data {
        padding: 5px;
      }
    }

    .order-created {
      width: calc(20% - 20px);
    }
    .order-type {
      width: calc(20% - 20px);
    }
    .order-amount {
      width: calc(20% - 20px);
    }
    .order-price {
      width: calc(20% - 20px);
    }
    .order-action {
      width: 100px;
    }
  }
}

.landingPage_main {
  .closing-orders-row {
    .inner-table {
      width: 100%;
    }
  }
}

.chilled-vault {
  content: url('/assets/image/vault.svg');
  &.themeNight {
    content: url('/assets/image/vault-night.svg');
  }
}

.trade-wallet {
  content: url('/assets/image/trade-wallet.svg');
  &.themeNight {
    content: url('/assets/image/trade-wallet-night.svg');
  }
}

.chilled-transaction-status {
  &.SUCCEEDED {
    color: $coin-zoom-green;
  }

  &.IN_PROGRESS {
    color: rgb(162, 181, 232);
  }

  &.FAILED {
    color: $coin-zoom-error;
  }

  &.UNKNOWN {
    color: rgb(134, 134, 134);
  }
}
