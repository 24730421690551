.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// triangle implementation

.loader-section,
.loader-section1,
.loader_open_order,
.loader_order_history,
.loader_wallet_withdrawal,
.loader_wallet_fiat,
.loader_wallet_crypto,
.loader_my_deposit_deposit,
.loader_my_deposit_trade,
.loader_open_order {
  .loader-transparent {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0.6;
    top: 0;
    left: 0;
  }

  #triangle,
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  #triangle {
    z-index: 9999;
  }

  svg#triangle {
    -webkit-transform-origin: 50% 65%;
    transform-origin: 50% 65%;
  }

  svg#triangle polygon {
    stroke-dasharray: 17;
    -webkit-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
    animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  }

  @-webkit-keyframes dash {
    to {
      stroke-dashoffset: 136;
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 136;
    }
  }
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.loader-section,
.loader-section1,
.loader_open_order,
.loader_order_history,
.loader_wallet_withdrawal,
.loader_wallet_fiat,
.loader_wallet_crypto,
.loader_my_deposit_deposit,
.loader_my_deposit_trade,
.loader_open_order {
  .loader-transparent {
    background: black;
  }
}

.position-relative {
  position: relative !important;
}
