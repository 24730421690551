@import './variable.scss';

/*
* Defines common styling to the material tables, mainly to make them look like the other tables 
* already used in the system.
*/

.table {
  .mat-header-row {
    height: auto;
  }
  .mat-cell,
  .mat-footer-cell {
    font-size: 12px;
  }
}

.themeNight {
  .table th.mat-header-cell {
    color: $coin-zoom-green;
    background-color: $coin-zoom-night-table-hdr-background;
    border-color: #000000;
  }
}

.table th.mat-header-cell {
  color: $coin-zoom-white;
  background-color: $coin-zoom-day-table-hdr-background;
  border-color: $coin-zoom-day-table-hdr-border;
}
