@import 'chartiq/css/chartiq.scss';
@import 'chartiq/css/normalize.css';
@import 'chartiq/css/stx-chart.css';
@import './variable.scss';

/* -------- Axis Styles -------- */
/* -------- Axis Styles -------- */
.stx_xaxis {
  /* x-axis date styles */
  color: #666;
}

.ciq-dropdowns {
  position: relative;
  z-index: 2;
}

.collapse:not(.show) {
  display: block !important;
  width: 70px;
  color: #fff;
}

.ciq-menu {
  z-index: 1;
}

.error-message {
  color: red;
}

.ciq-menu.stxMenuActive {
  color: #fff;
}

.ciq-menu:hover {
  color: #fff;
}

.display-none {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: $coin-zoom-scrollbar-track-colour;
}

::-webkit-scrollbar-thumb {
  background-color: $coin-zoom-scrollbar-thumb-colour;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: $coin-zoom-scrollbar-thumb-hover-colour;
}

::-webkit-scrollbar {
  height: 5px;
  background: #555;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgb(207, 198, 198);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-color-box {
  background-color: rgba(0, 0, 0, 0.05);
}

body {
  color: #464646 !important;

  scrollbar-face-color: $coin-zoom-scrollbar-thumb-colour;
  scrollbar-arrow-color: $coin-zoom-scrollbar-thumb-colour;
  scrollbar-track-color: $coin-zoom-scrollbar-track-colour;
  scrollbar-shadow-color: rgb(0, 0, 0);
  scrollbar-highlight-color: rgb(0, 0, 0);
  scrollbar-3dlight-color: #808080;
  scrollbar-darkshadow-color: #202020;
}

.p0 {
  padding: 0;
}

.form-control {
  height: 40px !important;
  font-size: 14px;
}

.table th {
  vertical-align: middle;
  padding-top: 5px;
}

.table td {
  vertical-align: middle;
  padding-top: 12px;
  padding-bottom: 10px;
}

html .themeNight {
  #ripple-loader {
    top: 50%;
    left: 50%;
    position: relative;
    margin-top: 20%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  #ripple-loader:before {
    /*   content:'Loading'; */
    position: absolute;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.3em;
    margin: 38% 0% 0% 14%;
    color: #000000;
  }

  #ripple-loader:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    -webkit-animation: ripple 1.5s ease-out infinite;
    -moz-animation: ripple 1.5s ease-out infinite;
    -ms-animation: ripple 1.5s ease-out infinite;
    -o-animation: ripple 1.5s ease-out infinite;
    animation: ripple 1.5s ease-out infinite;
  }

  @keyframes ripple {
    0% {
      width: 20px;
      height: 20px;
      opacity: 1;
    }

    96% {
      width: 20px;
      height: 20px;
      opacity: 0;
    }

    100% {
      width: 20px;
      height: 20px;
      opacity: 0;
    }
  }

  .active {
    background: $coin-zoom-green !important;
    color: white !important;

    &.carousel-item {
      background: initial !important;
    }
  }

  .border-green {
    border-color: 2px solid$coin-zoom-green !important;
  }

  .fromInput {
    color: black;
  }

  .form-control {
    color: white;
    background: black;
    border: 1px solid $coin-zoom-green !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;

    .upload-text {
      color: $coin-zoom-white;
    }
  }

  .label-css {
    padding-top: 12px;
    margin-bottom: 0;
    color: $coin-zoom-green;
  }

  .setting-option-btn {
    width: 100%;
    background: #0a0a0a;
    border: none;
    font-size: 16px !important;
    font-weight: 400;
    color: white;
    border-radius: 0px; // box-shadow: 0 2px 5px 0 #303232, 0 2px 10px 0 #303232;
  }

  .legal-option-btn {
    width: 100%;
    background: #0a0a0a;
    border: none;
    font-size: 16px !important;
    font-weight: 400;
    color: white;
    border-radius: 0px; // box-shadow: 0 2px 5px 0 #303232, 0 2px 10px 0 #303232;
  }

  .span-clr {
    color: $coin-zoom-green;
  }

  .night-heading {
    color: #ffffff !important;
    font-size: 18px;
  }

  .stx_candle_down,
  .stx_candle_up {
    // border-color: blue !important;
  }

  .bs-datepicker-body table td span {
    text-align: center !important;
  }

  .bs-datepicker-head button.next span,
  .bs-datepicker-head button.previous span {
    text-align: center !important;
  }

  .display-block {
    display: block !important;
  }

  .stx_candle_shadow_up {
    color: #b8dea8 !important;
  }

  .stx_candle_shadow_down {
    color: #e34621 !important;
  }

  .profile-container {
    border-radius: 5px;
    background: #000000 !important;
    padding: 0 !important;
  }

  .dropdown-css {
    box-shadow: 0 2px 5px 0 #303232, 0 2px 10px 0 #303232;
    padding: 5px;
    border-radius: 5px;
    width: 60px;
    background: black !important;
    border: 1px solid $coin-zoom-green !important;
    color: white;
    background: white;
  }

  .table .thead-dark th {
    color: $coin-zoom-green;
    background-color: $coin-zoom-night-table-hdr-background;
    border-color: #000000;
  }

  .table th {
    color: #ffffff;
    border-top: 1px solid #000000;
  }

  .table td {
    color: #ffffff;
    border-top: 1px solid #000000;
  }

  .table td.green-clr {
    color: #60bb46;
  }

  .table td.green-clr .trailing-zeros {
    color: #6b8479;
  }

  .card-header {
    padding: 15px;
    border-bottom: 1px solid #f2f2f2;
    background-color: black;
  }

  .main-card {
    min-height: 510px !important;
    border-radius: 5px;
    padding: 0 !important;
    box-shadow: 0 2px 5px 0 rgb(33, 36, 40), 0 2px 10px 0 rgb(33, 36, 40);
  }

  .second-card-header {
    padding: 15px;
    border-bottom: 1px solid #f2f2f2;
    background-color: black;
    box-shadow: 0 2px 5px 0 rgb(33, 36, 40), 0 2px 10px 0 rgb(33, 36, 40);
  }

  .balance-header {
    padding: 15px;
    border-bottom: 1px solid #f2f2f2 !important;
    background-color: black;
  }

  .header-title {
    color: white;
  }

  .zero-bal-filter {
    float: right;
    border: 1px solid $coin-zoom-green;
    margin-right: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 9px;
  }

  .span-white {
    color: white !important;
  }

  .input-css {
    border: 1px solid $coin-zoom-green;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    // background: black;
    color: #000000;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #1b1b1b;
  }

  .table-hover tbody tr:hover {
    background-color: #213188;
    color: $coin-zoom-white;
  }

  .lim {
    color: white !important;
  }

  .info-body {
    padding: 15px;
    background: black;
    color: $coin-zoom-green;
  }

  .profile-body-div {
    padding: 15px;
    color: $coin-zoom-green !important;
  }

  .container_watermark {
    background-image: url('/assets/image/coinzoom-water-mark.png') !important;
    background-repeat: no-repeat !important;
    background-size: 337px !important;
    background-position: 130px center !important;
  }
}

.hand-pointer-container {
  cursor: pointer;
}

.hand-pointer-container img {
  width: 22px;
  margin-top: -8px;
}

#ripple-loader {
  top: 50%;
  left: 50%;
  position: relative;
  margin-top: 20%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#ripple-loader:before {
  /*   content:'Loading'; */
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3em;
  margin: 38% 0% 0% 14%;
  color: #fff;
}

#ripple-loader:after {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  border: 2px solid #000000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  -webkit-animation: ripple 1.5s ease-out infinite;
  -moz-animation: ripple 1.5s ease-out infinite;
  -ms-animation: ripple 1.5s ease-out infinite;
  -o-animation: ripple 1.5s ease-out infinite;
  animation: ripple 1.5s ease-out infinite;
}

@keyframes ripple {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  96% {
    width: 20px;
    height: 20px;
    opacity: 0;
  }

  100% {
    width: 20px;
    height: 20px;
    opacity: 0;
  }
}

// chart -------------
.divider {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #fff;
  top: 100%;
}

.btn-primary {
  border-color: #000000 !important;
}

// .stx-ico-close
// {
//     display: none!important;
// }

.basic-button,
.basic-button:hover {
  text-align: center;
  padding: 5px 0;
  /* background-color: $coin-zoom-green !important; */
  border: 1px solid $coin-zoom-green !important;
  color: #000 !important;
  width: 100%;
  font-size: 14px;
  border-right: 0 !important;
  border-left: 0 !important;
  margin-bottom: 5px;
  border-top: 0 !important;
}

.custom-radio-box {
  .container {
    position: relative;
    padding-left: 21px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    display: inline-block;
    width: 40%;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkbox-div {
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: #eee;
      border-radius: 50%;
    }
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $coin-zoom-green;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 3px;
    left: 4px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: white;
  }
}

.stx-ico-close {
  display: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container_watermark {
  background-image: url('/assets/image/coinzoom-water-mark-white.png') !important;
  background-repeat: no-repeat !important;
  background-size: 337px !important;
  background-position: 130px center !important;
}

.web_container_watermark {
  background-position: center !important;
}

.error {
  color: $coin-zoom-error;
  padding: 5px 0 0 5px;
}

.error-class {
  display: none;
  color: $coin-zoom-error;
}

@media all and (orientation: landscape) {
  .web_container_watermark {
    // height: 283px !important;
  }

  .z-index-css,
  .mobile-right-padding {
    width: 500px;
  }
}
