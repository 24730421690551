.themeNight {
  .linkText-container {
    .linkText:hover {
      color: white !important;
    }
  }

  .button_manual {
    color: white !important;
  }

  .transaction-form {
    background: #303332 !important;
  }

  .withdraw-history-container {
    background: #000 !important;
  }

  .transaction-body-container {
    background: #303332 !important;
  }

  .activeButton {
    background-color: #68bc46 !important;
  }

  .online-body {
    background: #303332 !important;
  }

  .hand-pointer {
    cursor: pointer;
  }

  .hand-pointer img {
    width: 28px;
    margin-top: -9px;
  }

  .activeSettings {
    background-image: linear-gradient(90deg, #21610f, $coin-zoom-green) !important;
    color: #fff;
  }

  .setting-optins-btn-container {
    border: 1px solid #2b2b2b !important;
  }

  .setting-option-btn {
    margin-top: 0px !important;
  }

  .browse-hide {
    color: white !important;
  }

  .head-border {
    background-color: #464646 !important;
  }

  .terms-bottom-btn {
    background-color: #323232 !important;
  }

  .referral-data-div {
    .btn-div {
      .btn.btn-success {
        background: #fff !important;
        color: #000 !important;
      }
    }
  }

  .black-theme-box {
    background: #303332;
  }

  .deposit-icon {
    svg {
      fill: #fff;
    }
  }

  .theme-sensitive {
    color: white !important;
    background-color: black !important;
  }
}
