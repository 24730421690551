@import './variable.scss';

// Override unrestricted ChartIQ styling
input,
input:hover {
  background-color: inherit;
}

button:disabled {
  cursor: not-allowed !important;
}

.notAvailable {
  text-align: center;
  padding: 34px 0;
  font-size: 15px;
  color: #000;
}

.symbol-frame[_ngcontent-c4] {
  width: 115px !important;
  font-size: 10px !important;
  color: #fff !important;
}

.stx-panel-title.chart-title {
  display: none !important;
}

.chartContainer {
  .stx-panel-title.chart-title {
    display: none !important;
  }
}

.chartContainer1 {
  position: relative;
  height: 100%;
  border-bottom: 1px solid #c2c2c2;
  display: block;

  .stx-panel-title.chart-title {
    display: none !important;
  }
}

.chartHide {
  display: none;
}

.ciq-nav input {
  background-color: #ffffff !important;
}

.themeNight .ciq-nav input {
  background-color: #151f28 !important;
}

.chart-option {
  .footer-graph-options {
    display: inline-block;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    &.orderbook-chart {
      margin-left: 20px;

      svg {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
  }

  .image {
    width: 23px;
    margin-right: 5px;
    display: inline-block;
    height: 23px;
    background-size: contain;
    vertical-align: middle;

    &.candle_image {
      background-image: url('/assets/image/black-1.png');
    }

    &.depth_image {
      background-image: url('/assets/image/black.png');
    }
  }
}

.themeNight {
  .chart-option {
    .candle_image {
      background-image: url('/assets/image/candle-icon.png');
    }

    .depth_image {
      background-image: url('/assets/image/Depth-icon.png');
    }
  }

  .depth-detail-image {
    fill: $coin-zoom-white;
  }

  ul.pagination {
    .page-link {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #505050;
    }

    .page-item.active .page-link {
      background-color: $coin-zoom-blue;
      border-color: $coin-zoom-blue;
    }

    .page-item.disabled .page-link {
      color: #989898;
      background-color: #000;
      border: 1px solid #505050;
    }
  }

  .depth-chart-complete {
    background: transparent;
    border-top: 1px solid $coin-zoom-night-theme-background;
  }
}

body .chart-footer {
  .quick-link {
    width: 30px;
    float: right;
  }
}

.coininfo-footer-section {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
}

.depth-chart-complete {
  height: 100%;
  border-top: 1px solid $coin-zoom-white;
  background: $coin-zoom-white;
}

.chart-order-book {
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 100;

  .table-heading {
    background: #323232;
    text-align: right;

    li {
      color: #ffffff;
    }
  }

  .table-content {
    background: #000000;
    text-align: right;

    &.sell-case {
      //  text-align: left;
    }
  }

  ul {
    padding: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #323232;
    position: relative;

    li {
      &.shading-class-bid,
      &.shading-class-sell {
        position: absolute;
        height: 100%;
        width: 0%;
        background-color: rgba(96, 187, 70, 0.5);
      }

      &.shading-class-sell {
        background-color: rgba(226, 70, 70, 0.5);
      }

      display: inline-block;
      width: 25%;
      color: #000;
    }
  }
}

.depth-order-book-icon {
  position: absolute;
  right: 20px;
  width: 25px;
  bottom: 9px;
  border: 1px solid #ffffff !important;
  padding: 1px !important;
  border-radius: 3px;
  cursor: pointer;
  z-index: 1000;
}

.requ {
  padding: 0px 3px;
  color: red;
  font-size: 15px;
  font-weight: bold;
}

#studySelect {
  p {
    padding: 0 20px !important;
    color: #398dff !important;
  }

  .current-study-part {
    menu-option {
      span:last-child {
        float: right;
        font-size: 14px;
      }
    }
  }
}

.candle-chart-web-view {
  .container_watermark {
    background-position: center center !important;
  }
}

.mobile-right-padding menu-select,
.mobile-right-padding button {
  padding: 10px !important;
}

// pagination css
ul.pagination {
  margin-bottom: 0px;
  margin-top: 10px;

  .page-link {
    color: #000000;
    background-color: #fff;
    border: 1px solid #b7b7b7;
  }

  .page-item.active .page-link {
    background-color: #4a61b5;
    border-color: #4a61b5;
    z-index: 0;
  }

  .page-item.disabled {
    cursor: not-allowed;

    .page-link {
      background-color: #fff;
      border: 1px solid #b7b7b7;
    }
  }
}

.depth-detail-image {
  fill: $coin-zoom-night-theme-background;
}

// Safari input vertical alignment requires this top/bottom padding
input[type='text'],
input[type='number'] {
  padding: 0 5px 0 10px;
}

.themeNight .container a.cz-green {
  color: $coin-zoom-green !important;
}

.underline-form-control {
  width: 100%;

  input,
  input:focus {
    padding: 0 0 0 0.375rem;
    font-size: 16px;
    line-height: 1.5;
    border: none;
  }

  input:enabled:focus {
    box-shadow: none;
  }

  input:hover {
    background-color: inherit;
  }
}

.registration-tooltip .tooltip-inner {
  font-size: 125%;
}

// shows a bottom border under the selector's search option
lib-mat-select-search div {
  height: 33px !important;
}
