.coin {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
    width: 25px;
  }
  .coin-name {
    display: inline-block;
    .coin-name-full {
      line-height: 1em;
      text-align: left;
    }
    .coin-name-short {
      opacity: 0.5;
      font-size: 0.8em;
      text-align: left;
    }
  }
}

.transaction-scrollable-row {
  div.coin {
    display: flex;
    .coin-name {
      max-width: calc(100% - 30px);
      .coin-name-full,
      .coin-name-short {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.available-in-coin {
  text-align: right;
}
.available-in-usd {
  text-align: right;
  opacity: 0.5;
  font-size: 0.8em;
}
