// =============== margin =========================

.ml-0 {
  margin-left: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-20 {
  margin-top: 20px;
}

//==================== padding ============================

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pr-0 {
  padding-right: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-15 {
  padding-right: 15px;
}

//=======================text-align-center=======================

.txt-center {
  text-align: center;
}

//=======================vertical-align-middle======================

.vrt-align {
  vertical-align: middle;
}

//====================display-none================================

.dis-none {
  display: none;
}

//======================display-block===============================

.dis-block {
  display: block;
}

.min-h-38 {
  min-height: 38px;
}

.cursor-pointer {
  cursor: pointer;
}

.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 20px !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.width-100 {
  width: 100%;
}

.font-22 {
  font-size: 22px;
}

.font-16 {
  font-size: 16px;
}
