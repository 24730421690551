@import 'bootstrap-scss/bootstrap-grid';

/* Flex setup for header/footer fixed to top/bottom respectively while allowing the header/footer height to be dynamic */
body {
  position: fixed;
  height: 100%;
  width: 100%;
}

.flex-page,
.flex-page-lg {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
@include media-breakpoint-down(lg) {
  .flex-page,
  .flex-page-lg {
    width: 100%;
    padding-bottom: 5px;
  }

  .flex-page-lg {
    // try and fit the page in the viewport allowing for header and footer
    height: 75vh;
    min-height: 500px;
  }
}

.flex-header {
  flex: 0 0 auto;
}

.flex-main {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.flex-footer {
  flex: 0 0 auto;
  padding-top: 5px;
}

// ========================================

@media screen and (max-width: 1024px) {
  .left_side {
    display: block;
  }

  .custom-radio-box .container {
    font-size: 11px !important;
    width: 45% !important;
    font-weight: 600;
  }

  .landingPage_main .right_panal .orderEx .card-title2 {
    font-size: 13px !important;
  }

  .landingPage_main .midil_panal .openOrder .result .subtab {
    min-height: 165px !important;
    max-height: 165px !important;
  }

  .table_tab {
    font-size: 11px !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .right_panal,
  .panel_left,
  .midil_panal {
    width: 100% !important;
  }

  .repo {
    display: none;
  }

  .terms-bottom-btn {
    margin-bottom: 42px;
    margin-left: -20px;
  }

  .alert-danger {
    right: 20px !important;
  }

  .eTmngr {
    font-size: 12px !important;
  }

  .footer-right {
    margin-right: 20px;
  }

  .copyright-content {
    margin-left: 48px !important;
  }

  .table_tab {
    width: 27% !important;
  }

  .setting-heading-content {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 10px !important;
  }

  p.center {
    margin-left: 15px !important;
  }

  .night-heading-container {
    text-align: center;
  }

  .btn-div {
    width: 90px !important;
  }

  .btn-warning {
    margin-top: 10px;
  }

  .impor {
    width: 170px;
  }

  .disable-account-container {
    margin-left: -15px;
  }

  .main-container {
    max-height: 460px;
  }

  .mobileHead {
    display: block !important;
  }

  .otpmain .otpsub {
    width: 100% !important;
  }

  .zero-bal-filter {
    width: 100%;
    margin-right: 0 !important;
    margin-top: 5px;
  }

  .search-ul {
    width: 100%;
  }

  .eamil_div {
    border-right: none !important;
  }

  .search-ul .input-css.search-input {
    width: 100%;
  }

  .deposit-btn {
    margin-top: 5px !important;
  }

  // Small devices (landscape phones, 576px and up)

  .chart-footer {
    .footer-graph-options {
      margin-right: 5px !important;
      margin-left: 0px !important;
    }

    .chart-options {
      padding-right: 10px;
    }

    .chart-option span.orderbook-chart svg {
      margin-right: 9px;
    }
  }

  .mobile-header {
    display: block !important;
    height: 50px;
  }

  .left_side {
    display: none;
  }

  .clBnt {
    width: 400px !important;
  }

  .onlinecss {
    width: 100% !important;
  }

  .widthTran {
    padding: 65px 0 !important;
  }

  .amt {
    left: 20px !important;
    width: 81% !important;
  }

  .p10 {
    padding: 5px 20px 5px 0px !important;
  }

  .social .text {
    padding: 0px 25px !important;
    margin-top: 10px;
  }

  .refTableActive {
    padding: 7px 26px !important;
  }

  .details-containe {
    margin-left: 0px !important;
  }

  .footer .footerSub .listMenu .eStlUc .eTmngr {
    margin: 5px 5px !important;
  }

  .header-title {
    font-size: 14px !important;
    padding-right: 0px !important;
  }

  .withdaralHistory-section {
    width: 50% !important;
  }

  .imageAling .boder {
    left: 50px !important;
  }

  .imageAling {
    padding-left: 16px;
  }

  .alert-msg {
    width: 200px;
    margin-left: -20px;
  }

  .disp-inline-blck {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .landingPage_main {
    .panel_left {
      width: 100% !important;
    }

    .midil_panal,
    .right_panal {
      width: 100% !important;
    }

    .right_panal {
      display: flex;
    }

    .trade_history {
      width: 48%;
      margin-left: 20px;
    }

    .orderEx {
      width: 48%;
    }
  }

  .amt {
    width: 65% !important;
  }

  .button_manual {
    width: 130px !important;
  }

  .form-div {
    padding: 0px !important;
    min-height: 295px !important;
  }

  p.center {
    margin-left: 140px !important;
  }

  .deposit-btn {
    margin-top: 5px !important;
  }

  .widthTran {
    margin-top: 0px !important;
    padding: 15px !important;
  }

  .widthdraw-content {
    width: 430px;
    margin-left: -13px;
  }

  .btn-container {
    margin-left: -15px !important;
  }

  .required {
    text-align: right !important;
    width: 400px !important;
    text-align: right;
  }

  .icon-nav-side {
    display: none;
  }

  .header .bg-light .navbar-right a {
    padding: 2px 7px !important;
  }

  .navbar-brand img {
    width: 128px !important;
  }

  .deposit-container {
    padding: 15px;
  }

  .chartsection > div:first-child {
    display: none;
  }

  .chart-footer.row {
    top: 430px !important;
  }

  .trade_history {
    margin-top: 0px !important;
  }

  .profile-tabs.setting-heading-content {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 10px !important;
  }

  .card_main .card .loop img {
    width: 35px !important;
    margin: 0px 10px 0 10px !important;
  }

  .main_c {
    min-width: 100% !important;
  }

  .card_main .card .loop span {
    font-size: 12px !important;
  }

  .card_main .card .loop .action {
    margin: 0 !important;
  }

  .zoomcoin .head .right_H {
    width: 45% !important;
    height: 275px;
  }

  .zoomcoin .head .left_H {
    width: 41% !important;
    height: 275px;
  }

  .zoomcoin .head .right_H .text_L .zoomInput .r input {
    border: none !important;
    text-align: right;
    font-size: 16px;
    width: 63%;
    margin-left: 70px;
    margin-top: -18px;
  }

  .deposit-history-container {
    padding: 0px !important;
  }

  .transaction-form {
    margin-bottom: 15px;
  }

  .otpmain .otpsub {
    width: 45% !important;
  }

  .kyc-confirm-checkbox label {
    width: 500px !important;
  }

  .sharsection {
    display: block;
    padding: 0 !important;
    width: 560px !important;

    .text {
      max-width: 100%;
    }
  }

  .clBnt {
    width: 400px !important;
  }

  .axb {
    float: right !important;
    font-weight: 600;
    font-size: 13px;
    word-spacing: 4px;
  }

  .ioa {
    font-weight: 600;
    font-size: 13px;
    word-spacing: 4px;
  }

  .amt {
    position: absolute;
    width: 50% !important;
    right: 0;
  }

  .coininfo-footer-section.send {
    position: unset !important;
  }

  .send .footer {
    position: unset !important;
  }

  .eTmngr {
    font-size: 10px !important;
  }

  .terms-bottom-btn {
    bottom: 235px !important;
    margin-bottom: 110px;
  }

  .col-md-5.qRcodesection {
    text-align: center !important;
  }

  .activity-log-container {
    margin-top: 10px;
  }

  .withdraw-header {
    width: 50%;
  }

  .main-container {
    margin-top: 20px;
    max-height: 142px;
    padding-top: 0px;
  }

  h2.night-heading.night-heading-container {
    text-align: center;
    margin-top: 10px;
  }

  .refContainer {
    display: inline-block;
  }

  .shareIocns {
    top: -18px;
  }

  .tweet-container {
    margin: 15px 2px !important;
  }

  .boder {
    z-index: unset !important;
  }

  .imgdiv {
    z-index: unset !important;
  }
}

//landing page market table needs to shrink to fit on smaller laptops.
@media (max-width: 1500px) {
  .landingPage_main {
    .panel_left {
      .result {
        .marketList {
          tbody {
            tr {
              td {
                span.trailing-zeros {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-height: 800px) and (max-height: 1200px) {
  .amount-label {
    left: 42% !important;
  }

  .activeTbody {
    max-height: 716px !important;
  }

  .main-content-container {
    height: 900px !important;
    overflow-y: auto !important;
  }

  .thead-dark {
    width: 100% !important;
  }

  .tbodytr tr {
    width: 100% !important;
  }

  .transaction-container {
    overflow: unset !important;
  }

  .main-container {
    height: 900px !important;
  }

  .submit-btn-div {
    margin-top: 25px !important;
  }

  .profile-data-div.notification-section {
    border: 1px solid #242424;
  }

  .table-scroll-section {
    height: 743px !important;
    overflow-y: auto !important;
  }

  .form-m-top {
    border: unset !important;
  }

  td.thtab {
    width: 21vw !important;
  }
}

@media (min-height: 1201px) and (max-height: 1500px) {
  .amount-label {
    left: 44% !important;
  }

  .themeNight .container-fluid .send-top-card {
    min-height: 645px !important;
  }

  .activeTbody {
    max-height: 1180px !important;
  }

  .main-content-container {
    height: 900px !important;
    overflow-y: auto !important;
  }

  .thead-dark {
    width: 100% !important;
  }

  .tbodytr tr {
    width: 100% !important;
  }

  .bank-notification {
    height: 1290px !important;
  }

  .transaction-container {
    overflow: unset !important;
  }

  .main-container {
    height: 1300px !important;
  }

  .submit-btn-div {
    margin-top: 25px !important;
  }

  .table-scroll-section {
    height: 1135px !important;
    overflow-y: auto !important;
  }

  .bank-table-scroll {
    height: 1185px !important;
    overflow-y: auto !important;
  }

  .form-m-top {
    border: unset !important;
  }

  td.thtab {
    width: 21vw !important;
  }
}

.search-input-container {
  width: 80% !important;
}
